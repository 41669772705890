export const COUNTRIES_WITH_DELIVERIES = ['BR', 'AR'];
export const COUNTRIES_WITH_DEFAULT_ORDERS_ERROR_COLUMN = ['BR'];
export const LINK_INFORMATION_CENTER = '/commercial-control/bees-information-center';
export const LINK_COMMERCIAL_CONTROL_DELIVERIES = '/commercial-control/deliveries';
export const LINK_COMMERCIAL_CONTROL_ORDERS = '/commercial-control/orders';
export const LINK_COMMERCIAL_CONTROL_ORDER_DETAILS = '/commercial-control/orders/order-details';
export const LINK_INFORMATION_CENTER_DELIVERIES_DETAILS =
  '/commercial-control/deliveries/deliveries-details';
export const GET_HELP_URL =
  'https://ab-inbev.atlassian.net/servicedesk/customer/portal/5/group/74/create/8859';
